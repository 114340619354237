<template>
  <div
    class="vp-p-4 vp-rounded-lg vp-max-w-md"
    :class="{
      'vp-ml-auto vp-bg-success-100 ': isOut,
      'vp-bg-gray-100': isIn,
    }"
  >
    <div
      class="vp-font-semibold vp-mb-1"
      :class="{
        'vp-text-success-400': isOut,
        'vp-text-gray-400': isIn,
      }"
    >
      {{
        message.sender?.name ||
        message.sender?.mobileNumber?.internationalFormat
      }}
    </div>

    <slot></slot>

    <p
      class="vp-text-xs vp-mt-2"
      :class="{
        'vp-text-success-400': isOut,
        'vp-text-gray-400': isIn,
      }"
      :title="absoluteDate"
    >
      {{ distanceDate }}
    </p>

    <span
      class="vp-text-xs vp-inline-block"
      :class="{
        'vp-text-gray-400': isIn,
        'vp-text-success-400': isOut,
      }"
    >
      {{ message.sender?.mobileNumber?.internationalFormat }}
    </span>
  </div>
</template>

<script>
import { formatDistanceToNow } from "date-fns";

export default {
  props: {
    direction: String,
    created: String,
    message: Object,
  },
  computed: {
    distanceDate() {
      return formatDistanceToNow(this.created * 1000, { addSuffix: true });
    },
    absoluteDate() {
      return new Date(this.created * 1000).toLocaleString();
    },

    isOut() {
      return this.direction.toLowerCase() == "out";
    },

    isIn() {
      return this.direction.toLowerCase() == "in";
    },
  },
};
</script>
