<template>
  <div
    class="vp-max-w-3xl vp-mx-auto vp-p-8 vp-h-screen vp-overflow-auto vp-relative"
    v-chat-scroll="{ always: false, handlePrepend: true }"
    ref="chats"
  >
    <div v-show="loading" class="vp-flex vp-justify-center vp-p-4">
      <Loader class="vp-w-8 vp-h-8 vp-block vp-animate-spin vp-text-gray-500" />
    </div>
    <template v-if="!loading">
      <template v-if="messages.length">
        <ul class="vp-space-y-4">
          <li class="vp-flex" v-for="message in messages" :key="message.id">
            <ChatBubble
              :direction="message.direction"
              :created="message.receivedOn"
              :message="message"
            >
              <ChatBubbleText :text="message.text" />
            </ChatBubble>
          </li>
        </ul>
        <p class="vp-text-xs vp-text-gray-500 vp-text-center vp-mt-8">
          There are total
          {{ (pagination && pagination.total) || "..." }} messages.
        </p>
      </template>

      <div v-else class="vp-text-xs vp-text-gray-500 vp-text-center vp-mt-8">
        No Chat Found
      </div>
    </template>
  </div>
</template>

<script>
import chatMessages from "@/graph/chatMessage/get.gql";
import ChatBubble from "@/components/ChatBubble.vue";
import ChatBubbleText from "@/components/ChatBubbleText.vue";
import { Loader } from "icons/icons.js";

export default {
  components: {
    Loader,
    ChatBubble,
    ChatBubbleText,
  },

  data() {
    return {
      loading: true,
      messages: [],
      pagination: null,
    };
  },

  created() {
    this.get();
  },

  mounted() {
    this.$refs.chats.addEventListener("scroll", () => {
      if (this.$refs.chats.scrollTop === 0) {
        this.get();
      }
    });
  },

  methods: {
    get() {
      const { contactId, campaignId, token } = this.$route.query;
      if (!contactId || !campaignId) return;

      this.loading = true;
      this.$query(
        chatMessages,
        {
          contactId: Number(contactId),
          page: this.pagination ? this.pagination.currentPage + 1 : 1,
        },
        {
          // Due to Cross site domain cookie read issue, need to manually pass from here
          headers: {
            Authorization: `Bearer ${token}`,
            campaignId: campaignId,
          },
        }
      )
        .then(
          ({
            data: {
              chatMessages: { data, pagination },
            },
          }) => {
            const chats = [...data].reverse();
            this.messages.unshift(...chats);
            this.pagination = pagination;
          }
        )
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
