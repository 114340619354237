import "./style/main.css";
import "./global";

import Vue from "vue";

import { apolloClient } from "./plugins/apollo.js";
import VueApollo from "vue-apollo";
import VueChatScroll from "vue-chat-scroll";

import App from "./App.vue";
import router from "./router";

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(VueChatScroll);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  apolloProvider,
}).$mount("#embed");
