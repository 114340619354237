<template>
  <div class="vp-break-words vp-whitespace-pre-line vp-text-sm">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>
