<template>
  <Root v-if="$route.query.token">
    <RouterView />
  </Root>
  <Root v-else>
    <p>Authentication Required.</p>
  </Root>
</template>
<script>
import Root from "components/src/root.vue";
import { setToken, setCampaign } from "plugins/utils";

/**
 * http://embed.vepaar.local:7003/#/conversation?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYTE4OTE5MGExNWNiYjJkMWNkMGJlOWIyYmNjZmZkZTBlNDQ4NjRkMjRkM2U2YjFiMWMxOWE1MTY5NTE4YjEyZWYyNTdjYjMzYTliM2MzMjciLCJpYXQiOjE2OTc1MjI0NjcuNDM4NzQ1LCJuYmYiOjE2OTc1MjI0NjcuNDM4NzQ3LCJleHAiOjE3MjkxNDQ4NjcuNDMyNzEsInN1YiI6IjY1Iiwic2NvcGVzIjpbXX0.Gfq7bka_a5cfl2q8lJeA2zRLU-Ebck6BQiqYNZt_wXxYqDUOTzp4_qG1lMKAftwsgpblpwVGll3UE_THL9I4YrhPsE9ZNe7dOQtVmFVpxZRIH5QJ4Hz5BYot-NBK-cTloNvnxVVQEYmEEmd2mrx5bJ_nd1Vf0oD_4kgfcatutaDjX5CU3hRtdov3FoMjAX06pNzAFQnlzvc5RIEqNGKxWRf_suRRxGoLIGOu4DxhA7GAgamKwHYeVwaA1Hw8B72FhjpSBxUQAnu2omTxGEKTWx9-heFGt6hXotjao6_xoBezU0jBqMwm15b8EOA-8Ki0wzqvBjscFKzRfYo_fuQZ8amsTqKC_VKI6J9CB6DxqOXRRj9ocVI6-cNMZOqzrtsYxi-haGWAfls7atH8R-BK6pb5f5Nl7znGsvgjdreCaZPApI8soJspIiz8-bdkJCdU1PXlXEf0g7QWqE3gza7K5VeyILFAb9kd0Jfozzs3zV-HaY7hrFPko64iPeSnuHdBZXkvlmpJOxQhm4p5D-zLiOcvYHrOPWVtrPEIxvubi7V0Kx9zoVBMtR-Yz7_0BqIkIRgg2oYtFHRqI51Vd86OtxfqtCRDzUKejaIvzVHZVECp0QUECzFkm-zoDNv8gFOUT69lOOw0HlHLJlgKw7dkEmGSYnv8scvfjoGugrDNifI&campaignId=57&contactId=11067695
 */
export default {
  components: {
    Root,
  },

  created() {
    if (this.$route.query.token) {
      setToken(this.$route.query.token);
    }

    if (this.$route.query.campaignId) {
      setCampaign(this.$route.query.campaignId);
    }
  },
};
</script>
