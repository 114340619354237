/**
 * Due to cross site cookie read blocked by hubspot.com, therefore using this apollo.js file
 */

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { cloneDeep } from "lodash-es";
import graphqlTag from "graphql-tag";

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them

  const _headers = {
    ...headers,
  };

  return {
    headers: _headers,
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const gql = (query) =>
  graphqlTag`
      ${query}
    `;

export const query = (query, variables, options) =>
  apolloClient
    .query({
      query: gql(query),
      variables,
      context: options,
    })
    .then((res) => cloneDeep(res));
